<template>
  <div style="max-width: 720px; margin:0 auto;">
    <h4 class="form-title">{{ opts.formName }}</h4>

    <el-form label-width="80px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="时间:" prop="createDate" style="float:right;">
            {{ $util.fmtDate(opts.createDate, "yyyy年MM月dd日") }}
          </el-form-item>
          <el-form-item label="申请人:" style="float:right;">
            {{ opts.createUserName || '' }}
          </el-form-item>
          <el-form-item label="单号:" style="float:right;">
            {{ opts.billCode || '' }}
          </el-form-item>
          <div style="clear: both;"></div>
        </el-col>
      </el-row>
    </el-form>

    <el-form ref="forms" :model="forms" :rules="rules" class="form-inline" label-position="right">
      <el-row>
        <el-col :span="12">
          <el-form-item label="公司主体" prop="companyId" label-width="110px">
            <el-tree-select ref="companys" prop="companyId" v-model="forms.companyId" :data="companys" clearable
              @change="forms.departmentId = null; companyChange()" check-strictly :render-after-expand="false"
              style="max-width:280px" :disabled="!startNode" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="申请部门" prop="departmentId" label-width="110px">
            <el-tree-select ref="departments" v-model="forms.departmentId" :data="departments" check-strictly
              :render-after-expand="false" placeholder="先选择所属公司" style="width: 100%" :disabled="!startNode" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="选择印章" prop="sealType" label-width="110px">
            <el-select v-model="forms.sealType" placeholder="要使用的印章" :disabled="!startNode" style="width: 180px">
              <el-option label="公章" value="公章" />
              <el-option label="合同章" value="合同章" />
              <el-option label="私章" value="私章" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="希望盖章时间" prop="sealDate" label-width="110px">
            <el-date-picker v-model="forms.sealDate" type="date" placeholder="希望盖章时间" :disabled="!startNode"
              style="width: 200px" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="申请原因" prop="reasons" label-width="110px">
        <el-input v-model="forms.reasons" :disabled="!startNode" placeholder="请填写申请原因及盖章说明" />
      </el-form-item>

      <el-form-item label="文件名称" prop="fileName" label-width="110px">
        <el-input v-model="forms.fileName" :disabled="!startNode" placeholder="请填写盖章文件的名称以及包含的主要信息" />
      </el-form-item>
      <el-form-item label="对方公司全称" prop="partnerName" label-width="110px">
        <el-input v-model="forms.partnerName" :disabled="!startNode" />
      </el-form-item>


      <el-form-item class="no-print" label="">
        <b>流程备注：</b>
        <p style="width:100%; text-indent: 2em;">申请人在填写表单时，需确保提供准确、完整的信息。</p>
        <p style="width:100%; text-indent: 2em;">印章使用必须遵循公司相关制度和流程，未经审批不得擅自使用印章。</p>
        <p style="width:100%; text-indent: 2em;">审核人应认真审查申请内容，并在规定时间内给予反馈。</p>
        <p style="width:100%; text-indent: 2em;">使用后的印章应及时归还并做好记录，以便后续查询和管理。</p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "sealapply",
  components: {},
  data() {
    return {
      initOpts: {
        formType: 'YZSQ',
        formCode: 'sealapply',
        formName: '印章使用申请单',
        flowCode: 'oa-sealapply',
        status: 0,
      },
      forms: this.formdata,
      companys: [],
      departments: [],

      rules: {
        companyId: [
          { required: true, message: "请选择要申请的公司", trigger: "blur" },
        ],
        departmentId: [
          { required: true, message: "请选择要申请的部门", trigger: "blur" },
        ],
        sealType: [
          { required: true, message: "请选择要使用的印章名称", trigger: "blur" },
        ],
        reasons: [
          { required: true, message: "请输入申请的具体事由", trigger: "blur" }
        ]
      },
      dict: {},
    };
  },
  props: {
    userinfo: {
      type: Object,
      default: {},
    },
    opts: {
      type: Object,
      default: {}
    },
    formdata: {
      type: Object,
      default: {},
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getInitOpts() {
      return this.initOpts;
    },
    //只有审核的时候才会校验（保存草稿不校验）
    async formCheck() {
      if (!this.forms.companyId || !this.forms.departmentId) {
        this.$message({ type: "info", message: "请选择对应的公司和部门", });
        return false;
      }

      let companyDto = this.$refs.companys.getNode(this.forms.companyId);
      let departmentDto = this.$refs.departments.getNode(this.forms.departmentId);
      if (companyDto && companyDto.data && companyDto.data.companyName)
        this.forms.companyName = this.$refs.companys.getNode(this.forms.companyId).data.companyName;
      if (departmentDto && departmentDto.data && departmentDto.data.departmentName)
        this.forms.departmentName = this.$refs.departments.getNode(this.forms.departmentId).data.departmentName;

      if (!this.forms.companyName || !this.forms.departmentName) {
        this.$message({ type: "info", message: "请选择对应的公司和部门", });
        return false;
      }

      this.opts.formDesc = '[' + this.forms.sealType + ']';
      if (this.forms.sealDate)
        this.opts.formDesc += '希望盖章时间:' + this.$util.fmtDate(this.forms.sealDate, "yyyy-MM-dd") + ';';
      if (this.forms.fileName)
        this.opts.formDesc += (this.forms.fileName || '') + ';';
      if (this.forms.partnerName)
        this.opts.formDesc += (this.forms.partnerName || '');

      //console.log(this.opts,this.formdata);
      //this.opts.fileNumber <= 0&&
      if (this.opts.files.length <= 0) {
        this.$message({ type: "info", message: "请上传对应的附件内容", });
        return false;
      }

      let p = this.$refs['forms'].validate()
      try {
        await p
      } catch (error) {
        //此处的error为表单校验失败的字段
        if (error) return false;
      }
      //console.log(p)

      return true;
    },
    getForms() {
      return this.forms;
    },

    companyChange() {
      //this.forms.departmentId = null;
      var that = this;
      if (!this.forms.companyId) {
        return;
      }

      this.$kaung.departments(this.forms.companyId).then((res) => {
        that.departments = res;
      });
    },
  },
  created() {
    let that = this;

    if (this.opts && this.opts.flowCode)
      this.initOpts = JSON.parse(JSON.stringify(this.opts));

    this.$kaung.companys().then((res) => { that.companys = res; });

    this.companyChange();
  },
  watch: {
    formdata: function (newValue, oldValue) {
      //console.log(newValue);
    },
  },
  computed: {
    startNode() {
      //草稿或者退回状态
      if (this.initOpts.status == 0 || this.initOpts.status == -10 || this.initOpts.status == '') {
        return true;
      }
      return false;
    },
    endNode() {
      if (this.initOpts.status == 50) {
        return true;
      }
      return false;
    }
  }

};
</script>

<style scoped></style>